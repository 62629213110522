import React, { useState } from 'react';

export default function FAQ() {
  // State to track which FAQ item is active
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to handle toggling FAQ items
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section id="faq" className="faq section">
      <div className="container">
        <div className="row gy-4">

          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="content px-xl-5">
              <h3><span>Frequently Asked </span><strong>Questions</strong></h3>
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit.
              </p> */}
            </div>
          </div>

          <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
            <div className="faq-container">
              {faqItems.map((item, index) => (
                <div 
                  key={index} 
                  className={`faq-item ${activeIndex === index ? 'faq-active' : ''}`} 
                  onClick={() => handleToggle(index)}
                >
                  <h3>
                    <span className="num">{index + 1}.</span> 
                    <span>{item.question}</span>
                  </h3>
                  {activeIndex === index && (
                    <div className="faq-content">
                      <p>{item.answer}</p>
                    </div>
                  )}
                  <i className={`faq-toggle bi ${activeIndex === index ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// Sample FAQ items
const faqItems = [
  {
    question: 'How does FinSync AI work?',
    answer: 'FinSync AI uses advanced algorithms to analyze vast amounts of market data. It identifies trends, forecasts future movements, and generates detailed reports, offering you valuable insights into financial markets.',
  },
  {
    question: 'How often does FinSync AI update its data?',
    answer: 'FinSync AI offers real-time updates, ensuring that you have the most current market information and insights available.',
  },
  {
    question: 'How secure is FinSync AI?',
    answer: 'FinSync AI prioritizes the security of your financial data. Our platform employs robust security measures to ensure the confidentiality and integrity of your information.',
  },
  {
    question: 'What kind of support does FinSync AI offer?',
    answer: 'FinSync AI provides dedicated support from a team of financial and AI experts. Whether you need technical assistance or have questions about using the platform, we’re here to help.',
  },
  {
    question: 'Can I customize the reports generated by FinSync AI?',
    answer: 'Yes, you can customize reports to meet your specific needs, including adjusting metrics and visualizations to fit your analysis requirements.',
  },
];
