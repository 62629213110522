import Nav from "../components/header";
import Hero  from "../components/hero";
import IconBoxSection from "../components/iconbox";
import Services from "../components/service";
import Partners from "../components/partners";
import AboutUs from "../components/about";
import Testimonials from "../components/testimonial";
import Pricing from "../components/pricing";
import BlogSection from "../components/bloglist";
import Contact from "../components/contact";
import Footer from "../components/footer";

export default function Home(){
return(
<>

<Nav/>
<Hero/>
<IconBoxSection/>
<Services/>
<Partners/>
<AboutUs/>
<Testimonials/>
<Pricing/>
<BlogSection/>
<Contact/>
<Footer/>






</>
);


}