import React from "react";
import Nav from "../components/header";
import Footer from "../components/footer";
import SingleBlog from "../components/singleblogpage";





export default function Blog(){
    return(
    <>
    
    <Nav/>
    {/* <Hero/>
    <IconBoxSection/>
    <Services/>
    <Partners/>
    <AboutUs/>
    <Testimonials/>
    <Pricing/>
    <BlogSection/>
    <Contact/> */}
    <SingleBlog/>

    <Footer/>
    
    
    
    
    
    
    </>
    );
}